import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA

const Contact = () => {
    const Blogdata1 = [
        {
            id: 1,
            title: "Téléphone",
            cover: "./images/telephone-call.png",
            category: "+216 74 433 020",
        },
        {
            id: 2,
            title: "Email",
            cover: "./images/email.png",
            category: "gm.polysoftco@gmail.com",
        },
        {
            id: 3,
            title: "Adresse",
            cover: "./images/location-pin.png",
            category: "Rte de Teniour Km 1,5 / Sfax, Tunisia, 3000",
        },
    ];

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        site: '', // Added field for site choice
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const [captchaToken, setCaptchaToken] = useState(null); // State for storing reCAPTCHA token

   

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token); // Store the token on captcha verification success
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Validate the form fields
        if (!formData.name || !formData.email2 || !formData.site || !formData.message) {
            alert("Please fill in all fields.");
            return;
        }
        if (!captchaToken) {
            alert("Please complete the reCAPTCHA.");
            return;
        }
        const emailParams = {
            from_name: formData.name,
            tel: formData.tel,
            Adresse: formData.Adresse,
            email2: formData.email2,
           // subject: formData.subject,
            message: formData.message,
            site: formData.site, 
        };
    
        emailjs.send('service_u3i7xfs', 'template_jah4wpf', emailParams, 'aftmeehiwnH-ym2V8')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setFormData({ name: formData.name,tel: formData.tel,Adresse: formData.Adresse, email2: formData.email2,
                    // subject: '',
                      message: formData.message, site: formData.site }); // Reset form
                alert("Email envoyé avec succès!...", response.status, response.text); 
            })
            .catch((err) => {
                console.error('Failed to send email. Error:', err);
                alert("Failed to send email. Please try again later."); 
            });
    };
    
    return (
        <>
            <section className='back11'>
                <div className="boxB">
                    <div className="headingB">
                        <br />
                        <h3>C'est l'endroit idéal</h3>
                        <hr className="solid"></hr>
                        <h1>Contactez-nous</h1>
                    </div>
                </div>
            </section>

            <div className="container MarginTop ">
                <div className="contain grid topMargin">
                    {Blogdata1.map((val) => {
                        return (
                            <div className="container4" key={val.id} data-aos='flip-left'>
                                <img src={val.cover} alt="" className="image2" />
                                <div className="text marginmoin">
                                    <span>{val.date}</span>
                                    <h2>{val.title}</h2>
                                    <p>{val.category} </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <section className="skill" style={{ padding: "0px" }}>
                <div className="container ">
                    <div className="heading  topMargin" data-aos='zoom-in-down'>
                        <h3>CONTACTEZ-NOUS</h3>
                    </div>
                </div>
            </section>
            <div className="div">
                <div className="form-content">
                    <form className="form2" onSubmit={handleSubmit}>
                        <div className="field input-field">
                            <input 
                                type="text" 
                                placeholder="Nom et prénom" 
                                className="input" 
                                name="name" 
                                value={formData.name} 
                                onChange={handleChange} 
                                data-aos='flip-left' 
                            />
                        </div>
                        <div className="field input-field">
                            <input 
                                type="text" 
                                placeholder="N°Tel" 
                                className="input" 
                                name="tel" 
                                value={formData.tel} 
                                onChange={handleChange} 
                                data-aos='flip-left' 
                            />
                        </div>
                        <div className="field input-field">
                            <input 
                                type="text" 
                                placeholder="Adresse" 
                                className="input" 
                                name="Adresse" 
                                value={formData.Adresse} 
                                onChange={handleChange} 
                                data-aos='flip-left' 
                            />
                        </div>
                       
                        <div className="field input-field">
                            <input 
                                type="email" 
                                placeholder="Email" 
                                className="input" 
                                name="email2" 
                                value={formData.email2} 
                                onChange={handleChange} 
                                data-aos='flip-right' 
                            />
                        </div>
                        {/* <div className="field input-field">
                            <input 
                                type="text" 
                                placeholder="sujet" 
                                className="input" 
                                name="subject" 
                                value={formData.subject} 
                                onChange={handleChange} 
                                data-aos='flip-up' 
                            />
                        </div> */}
                          <div className="field input-field">
                            <input 
                                type="email" 
                                placeholder="Email" 
                                className="input" 
                                name="email" 
                                value={'gm.polysoftco@gmail.com'} 
                                onChange={handleChange} 
                                data-aos='flip-right' 
                                disabled
                            />
                        </div>
                        <div className="field input-field">
                                <select 
                                    className="input" 
                                    name="site" 
                                    value={formData.site} 
                                    onChange={handleChange} 
                                    data-aos='flip-left'
                                    style={{ 
                                        height: '50px', 
                                        width: '100%', 
                                        marginTop: '0px', 
                                        borderRadius: '6px' 
                                    }}
                                >
                                    <option value="">Sélectionnez un logiciel</option>
                                    <option value="24-crm.com">24-crm.com</option>
                                    <option value="24-crm.tn">24-crm.tn</option>
                                    <option value="24-Paie">24-Paie</option>
                                    <option value="24-Gold">24-Gold</option>
                                    <option value="gestion-commerciale">Gestion commerciale</option>
                                    <option value="e-commerce">E-commerce</option>
                                    <option value="PolyCompta">PolyCompta</option>
                                    <option value="optic">Venus Optic</option>
                                    <option value="POLYTRESORERIE">PolyTrésor</option>
                                    <option value="POLYTRESORERIE">PolyPointage</option>
                                    <option value="POLYTRESORERIE">PVTactile</option>
                                    <option value="autre">Autre ...</option>
                                </select>
                            </div>

                        <div className="field1 input-field">
                            <textarea 
                                placeholder="Message" 
                                className="input" 
                                name="message" 
                                value={formData.message} 
                                onChange={handleChange} 
                                data-aos='flip-down' 
                            />
                        </div>
                      
                            {/* reCAPTCHA component */}
                            <div >
                            <ReCAPTCHA 
                          
                                sitekey="6LeAA2sqAAAAAMriCtN3sts53MmmilM0WXY2xFEI"
                                onChange={handleCaptchaChange}
                            />
                        </div>
                        <div className="field button-field">
                            <button type="submit" data-aos='zoom-in-up'>Envoyer</button>
                        </div>
                    </form>
                    <br /><br />
                </div>
            </div>
        </>
    )
}

export default Contact;

































// import React from 'react'
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import CountUp from 'react-countup';
// const Contact =()=> {
//     const Blogdata1 = [
//         {
//           id: 1,

//           title: "Télephone",
//           cover: "./images/telephone-call.png",

//           category: "+216 74 433 020",
//         },
//         {
//           id: 2,
 
//           title: "Email",
//           cover: "./images/email.png",

//           category: "gm.polysoftco@gmail.com",
//         },
//         {
//           id: 3,

//           title: "Adresse",
//           cover: "./images/location-pin.png",

//           category:"Rte de Teniour Km 1,5 / Sfax, Tunisia, 3000",
//         },
   
//       ]
//     return(<>
//       <section className='back11'>
//         <div className="boxB">
//         <div className="headingB">
//             <br/>
//                     <h3>C'est l'endroit idéal</h3>
//                     <hr className="solid"></hr>
//                     <h1>Contactez-nous</h1>
//                     </div></div>
//       </section>
   
//       <div className="container MarginTop ">
//       <div className="contain grid topMargin">
//                         {Blogdata1.map((val)=>{
//                             return(
//                                 <div className="container4"  key={val.id}  data-aos='flip-left'  > 
//                                  <img src ={val.cover} alt="" className="image2"/>
//                                         <div className="text marginmoin">
//                                             <span>{val.date}</span>

// <h2>{val.title}</h2>        
// <p>{val.category} </p>                                </div>
//                                         </div>
//                             )})}
//                                 </div></div>
 
  
//    <section className="skill" style={{padding:"0px"}}>
//             <div className="container ">
//                 <div className="heading  topMargin" data-aos='zoom-in-down' >
//                     <h3>CONTACTEZ-NOUS</h3>
                 
//                     </div></div></section>
//       <div className="div">
//             <div className="form-content">
             
//             <form  className="form2" >
              
//                 <div className="field input-field">
//                         <input type="text" placeholder="Nom et prénom" className="input" data-aos='flip-left'  />
//                     </div>
//                     <div className="field input-field">
//                         <input type="email" placeholder="Email" className="input" data-aos='flip-right' />
//                     </div>
                
//                     <div className="field input-field">
//                         <input type="text" placeholder="sujet" className="input"  data-aos='flip-up' />
//                     </div>
                
       
//                     <div className="field1 input-field">
//                         <textarea type="text" placeholder="Message" className="input" data-aos='flip-down' />
//                     </div>
                
//                     <div className="field button-field">
//                         <button type="submit" data-aos='zoom-in-up' >Envoyer</button>
//                     </div>
                  
                    
//                </form>
//                 <br/><br/>
//             </div>
//             </div>

//     </>)
// }
// export default Contact